import 'mdn-polyfills/NodeList.prototype.forEach';
import Swiper, { Navigation, Pagination } from 'swiper';

export default class Slider {
  constructor(element) {
    this.element = element;

    this.swiperElement = this.element;
    this.slides = this.element.querySelectorAll('[data-slider-slide]');
    this.prev = this.element.querySelector('[data-slider-control="prev"]');
    this.next = this.element.querySelector('[data-slider-control="next"]');
    this.spacing = 0;

    if(!this.swiperElement.classList.contains("swiper-simple")) {
      if(window.matchMedia("(max-width: 980px)").matches) {
        this.spacing=window.innerWidth*0.15;
      }
      else if(window.matchMedia("(max-width: 780px)").matches) {
        this.spacing=window.innerWidth*0.1;
      }
      else{
        this.spacing=200;
      }
    } else {
      if(window.matchMedia("(max-width: 980px)").matches) {
        this.spacing=window.innerWidth*0.1;
      }
      else if(window.matchMedia("(max-width: 780px)").matches) {
        this.spacing=window.innerWidth*0.05;
      }
      else{
        this.spacing=120;
      }
    }



    this.settings = {
      loop: true,
      navigation: { nextEl: this.next, prevEl: this.prev },
      slidesPerView: "auto",
      speed: 800,
      centeredSlides: true,
      spaceBetween: this.spacing,
      on: {
        init: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChange: (swiper) => {
          this.updateNavigation(swiper);
        }
      }
    };

    this.swiper;
  }

  updateNavigation(swiper) {
    this.prev.removeAttribute('disabled');
    this.next.removeAttribute('disabled');

    if (swiper.isBeginning) {
      this.prev.setAttribute('disabled', '');
    }
    if (swiper.isEnd) {
      this.next.setAttribute('disabled', '');
    }
  }

  mount() {
    Swiper.use([Navigation]);

    this.settings = {
      ...this.settings,
      loop: false,
      freeMode: true
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);

    this.swiper.on('reachBeginning', () => {
      this.prev.setAttribute('disabled', '');
    });

    this.swiper.on('reachEnd', () => {
      this.next.setAttribute('disabled', '');
    });

  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
