import 'mdn-polyfills/NodeList.prototype.forEach';
import Particule from './Particule';

document.addEventListener('DOMContentLoaded', () => {
  const particles = document.querySelectorAll('[data-particule]');
  if (!particles.length) {
    return;
  }

  particles.forEach((element) => {
    const particule = new Particule(element);
    particule.mount(element);
  });
});
