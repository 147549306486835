import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from '../toggler/Toggler';
import Navigation from '../navigation/Navigation';
import vhCheck from 'vh-check';

export default class Header {
  constructor(element) {
    this.element = element;
    this.navigationTogglers = [];
    this.height = this.element.offsetHeight;
    this.hasResized = true;
    this.tmpWidth = 0;
    this.navigation = null;
    this.navigationToggler = null;
    this.burger = null;
    this.isSticky = false;
    this.hasNavigationActive = false;

    this.handleNavigationHide = this.handleNavigationHide.bind(this);
    this.handleNavigationShow = this.handleNavigationShow.bind(this);
    this.handleNavigationUpdate = this.handleNavigationUpdate.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleNavigationHide() {
    this.element.classList.remove('has-navigation-active');
    this.hasNavigationActive = false;

    this.navigation.togglers.forEach((toggler) => {
      toggler.hide(true);
    });

    this.navigationToggler.focus();
    this.navigationToggler = null;
    this.updateNavigation();
  }

  handleNavigationShow(e) {
    this.navigationToggler = e.detail.toggler.element;
    this.element.classList.add('has-navigation-active');
    this.hasNavigationActive = true;
    this.updateNavigation();
  }

  handleNavigationUpdate() {
    this.updateNavigation();
  }

  handleResize() {
    this.hasResized = true;
  }

  calculate() {
    const vh = vhCheck();
    this.height = this.element.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${this.height}px`);
    document.documentElement.style.setProperty('--vh-offset', `${vh.offset}px`);
  }

  updateNavigation() {
    let isNavigationHasTogglerExpanded = false;

    this.navigation.togglers.forEach((toggler) => {
      if (!toggler.target.contains(toggler.element)) {
        isNavigationHasTogglerExpanded = toggler.expanded === true ? true : isNavigationHasTogglerExpanded;
      }
    });

    this.calculate();
  }

  updateResize() {
    if (this.tmpWidth !== this.element.offsetWidth) {
      this.height = this.element.offsetHeight;
      this.navigationTogglers.forEach((toggler) => {
        const isTogglable = window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none';

        if (toggler.mounted !== isTogglable) {
          if (isTogglable) {
            toggler.mount();
          } else {
            toggler.unmount();
            this.element.classList.remove('has-navigation-active');
            this.hasNavigationActive = false;
          }
        }
      });

      this.tmpWidth = this.element.offsetWidth;
      this.calculate();
    }
  }

  mount() {
    const nav = this.element.querySelector('#navigation');

    // setTimeout( function(){
      if (nav) {
        this.navigation = new Navigation(nav);
        this.navigation.element.addEventListener('navigation:update', this.handleNavigationUpdate);
        this.navigation.mount();
      }

      this.burger = this.element.querySelector('#header-burger');

      this.element.querySelectorAll('[data-header-toggler="navigation"]').forEach((element) => {
        const target = document.getElementById(element.dataset.headerToggler);
        const toggler = new Toggler(element, target);
        this.navigationTogglers.push(toggler);
        element.addEventListener('toggler:hide', this.handleNavigationHide);
        element.addEventListener('toggler:show', this.handleNavigationShow);

        if (
          window.getComputedStyle(this.burger).getPropertyValue('display') !== 'none'
        ) {
          toggler.mount();
        }
      });

      window.addEventListener('resize', this.handleResize);

      const loop = () => {
        if (this.hasResized) {
          this.updateResize();
          this.hasResized = false;
        }

        requestAnimationFrame(loop);
      }

      loop();

      this.element.classList.add('is-mounted');
      this.calculate();

    //}, 100);

  }

  unmount() {
    window.removeEventListener('resize', this.handleResize);

    if(this.navigationTogglers) {
      this.navigationTogglers.forEach((toggler) => {
        toggler.element.removeEventListener('toggler:hide', this.handleNavigationHide);
        toggler.element.removeEventListener('toggler:show', this.handleNavigationShow);
        toggler.unmount();
      });
      this.navigationTogglers = [];
    }

    if (this.navigation) {
      this.navigation.element.removeEventListener('navigation:update', this.handleNavigationUpdate);
      this.navigation.unmount();
      this.navigation = null;
    }

    this.element.classList.remove('is-mounted');
  }
}
