import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Blockitem {
  constructor(element) {
    this.element = element;
    this.header = document.querySelector(".c-header--main");
  }
  mount() {
    if(this.element.getAttribute('data-theme') === 'light') {
      this.header.style.color="#000";
    }else {
      this.header.style.color="#fff";
    }
  }
  unmount() {
  }
}
