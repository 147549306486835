import 'mdn-polyfills/NodeList.prototype.forEach';
import { CountUp } from 'countup.js';

export default class Viewport {
  constructor(element) {
    this.element = element;
  }

  mount() {
    this.element.classList.add("is-viewport");

    if(this.element.classList.contains("c-key")) {
      const counterElement = this.element.querySelector('.c-key__number');
      const counterValue = counterElement.textContent;

      const options = {
        useGrouping: false,
        };
        let counter = new CountUp(counterElement, counterValue, options);
        if (!counter.error) {
        counter.start();
        } else {
      }
    }

    if(this.element.classList.contains("is-observed--color")) {

      const event = new Event('viewport:intersecting');
      document.dispatchEvent(event);


      if(this.element.getAttribute('data-theme') === 'dark') {
        document.querySelector(".c-header--main").style.color="#fff";
        document.querySelector(".o-blocks--switch-color").style.color="#fff";
        document.querySelector(".o-blocks--switch-color").style.backgroundColor="#000";

        if(this.element.querySelector(".c-heading__glow")) {
          this.element.querySelector(".c-heading__glow").style.color="#000";
        }
      }
    }
  }

  unmount() {

    if(this.element.classList.contains("is-viewport")){
      this.element.classList.remove("is-viewport");


      const event = new Event('viewport:notIntersecting');
      document.dispatchEvent(event);

      if(this.element.classList.contains("is-observed--color")) {
        if(this.element.getAttribute('data-theme') === 'dark') {

          document.querySelector(".c-header--main").style.color="#000";
          document.querySelector(".o-blocks--switch-color").style.color="#000";
          document.querySelector(".o-blocks--switch-color").style.backgroundColor="#fff";
          if(this.element.querySelector(".c-heading__glow")) {
            this.element.querySelector(".c-heading__glow").style.color="#fff";
          }
        }
      }
    }
  }
}
