import 'mdn-polyfills/NodeList.prototype.forEach';

document.addEventListener('DOMContentLoaded', () => {
  if (window.matchMedia("(prefers-reduced-motion: no-preference)").matches) {

    const observedElements = document.querySelectorAll('.is-observed-procentage');
    if (!observedElements.length) {
      return;
    }
    function callback(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let scale = 0;
          scale = Math.round(((entry.intersectionRatio* 0.2)+0.8)*1000)/1000;
          if (scale>1) {
            scale = 1;
          }
          entry.target.style.transform = `scale(${scale})`;
          entry.target.style.opacity = Math.round(((entry.intersectionRatio))*1000)/1000;
        }
      });
    }

    const options = {
      threshold: Array.apply(null, {length: 100}).map((n, i) => i/100)
    }

    const observer = new IntersectionObserver(callback, options);

    Array.prototype.forEach.call(observedElements, (el) => {
      observer.observe(el);
    });
  }
  else {
  }
});
