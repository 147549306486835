import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Particule {
  constructor(element) {
    this.element = element;
    this.among = this.element.getAttribute("data-particule");
    this.containerHeight = this.element.offsetHeight;
    this.containerWidth = this.element.offsetWidth;
    this.averageSize = (this.containerHeight + this.containerWidth)* 0.5;
    this.colors = ["#B3DACC", "#BCB795"];
    this.maxPosition = 1.3;
    this.minPosition = 0.6;

    this.newParticle;
  }

  mount() {
    this.newParticle = document.createElement("div");
    this.newParticle.setAttribute("aria-hidden", "true");
    this.newParticle.classList.add("c-particule");

    this.element.appendChild(this.newParticle);



    for (let i = 0; i < this.among; i++) {

      this.randomColor = this.colors[Math.floor(Math.random() * this.colors.length)];
      this.randomDegree = (Math.floor(Math.random() * 360)) + "deg";
      this.randomPosition = (Math.floor(Math.random() * ((this.averageSize * this.maxPosition) - (this.averageSize * this.minPosition) + 1) + (this.averageSize * this.minPosition))) + "px";

      const newParticleElement = document.createElement("div");

      newParticleElement.style.backgroundColor=this.randomColor;
      newParticleElement.style.transform="rotate("+ this.randomDegree +") translateY("+ this.randomPosition+") rotate("+ this.randomDegree +")";

      this.newParticle.appendChild(newParticleElement);

    }

  }

  unmount() {
  }
}
