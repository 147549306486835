import Blockitem from './Blockitem';

document.addEventListener('DOMContentLoaded', () => {

    const observedElements = document.querySelectorAll('[data-theme]');
    if (!observedElements.length) {
      return;
    }
    let active = true;
    let halfHauter = window.innerHeight * (-1);

    document.addEventListener('viewport:intersecting', () => {
      active = false;
    });

    document.addEventListener('viewport:notIntersecting', () => {
      active = true;
    });

    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(active) {
            if(entry.isIntersecting){
              const observedElement = new Blockitem(entry.target);
              observedElement.mount();
            }
            else{
              const observedElement = new Blockitem(entry.target);
              observedElement.unmount();
            }
          }
        });
      }, {rootMargin: "0px 0px "+ halfHauter+"px 0px"});
      document.querySelectorAll('[data-theme]').forEach(observedElement => {
        observer.observe(observedElement) });
    }

});
