import 'mdn-polyfills/NodeList.prototype.forEach';
import AnimationIntro from './Animation-Intro';

document.addEventListener('DOMContentLoaded', () => {
  const animationElement = document.querySelectorAll('.js-animation--intro');
  if (!animationElement.length) {
    return;
  }

  animationElement.forEach((element) => {
    const animation = new AnimationIntro(element);
    animation.mount(element);
  });
});
