import 'mdn-polyfills/NodeList.prototype.forEach';

export default class AnimationIntro {
  constructor(element) {
    this.element = element;
    this.button = this.element.querySelector('.c-down');
    this.buttonHome = this.element.querySelector('.c-decouvrir--main');
    this.intro = this.element.querySelector('.o-wrapper--intro');
    this.scrollbar = document.querySelector('.c-scrollbar');
    this.thumbnail = document.querySelector('.o-blocks__item--thumbnail')
    this.thumbnailImage = document.querySelector('.o-blocks__item--thumbnail__image')
    this.contentBox = document.querySelector('.c-animation--intro__content')

    this.jump = this.jump.bind(this);
    this.resize = this.resize.bind(this);
    this.scroll = this.scroll.bind(this);
    this.update = this.update.bind(this);
    this.scrollDurationVhValue = this.element.getAttribute('data-duration');
    this.scrollOpacityEndValue = this.element.getAttribute('data-opacityend');


    this.scrollPosition=0;
    this.procentage= 0;

    this.needUpdate = true;
  }

  mount() {
    if(this.button) {
      this.button.addEventListener('click', this.jump);
    }
    if(this.buttonHome) {
      this.buttonHome.addEventListener('click', this.jump);
    }

    document.addEventListener('scroll', this.scroll);
    document.addEventListener('resize', this.resize);

    window.requestAnimationFrame(this.update);
  }

  jump() {
    let scrollDistance = document.documentElement.clientHeight;
    window.scrollBy(0, scrollDistance);

    this.intro.style.opacity = 0;

    if (window.matchMedia("(prefers-reduced-motion: no-preference)").matches) {

      // extra
      this.scrollbar.style.opacity = 0;

      if(this.thumbnail) {
        this.contentBox.style.scrollMarginTop= "250px";
        this.thumbnailImage.style.transform = "rotate(0deg)";
        this.thumbnailImage.style.opacity = 1;
      }

    }

  }

  update() {
    if (this.needUpdate) {

      this.scrollPosition = window.scrollY;

      if (!this.scrollDurationVhValue) {
        this.scrollDurationVhValue = 1;
      }

      if (!this.scrollOpacityEndValue) {
        this.scrollOpacityEndValue = 2;
      }

      if (this.scrollPosition< (window.innerHeight * this.scrollDurationVhValue)) {

        // principal
        this.procentage= Math.round(this.scrollPosition/(window.innerHeight * this.scrollDurationVhValue) * 100) / 100;
        this.intro.style.opacity = 1 - (this.scrollOpacityEndValue * this.procentage);

        let scaleValue = 1 - (this.procentage * 0.1) ;
        this.intro.style.transform = "scale("+ (scaleValue) +")";

        if (window.matchMedia("(prefers-reduced-motion: no-preference)").matches) {

          // extra
          this.scrollbar.style.transform = "translateY(-"+this.scrollPosition * 1.05+"px)";
          this.scrollbar.style.opacity = this.scrollOpacityEndValue - (this.scrollOpacityEndValue* this.procentage);

          if(this.thumbnail) {
            if(this.procentage<0.5){
              this.thumbnailImage.style.transform = "scale("+ (0.8 + ((this.procentage * 2) * 0.2)) +") rotate("+ ((-7) + ((((this.procentage * 2))) * 7)) +"deg)";
              this.thumbnailImage.style.setProperty('--thumbnail-opacity', 1- (0.2 + (this.procentage * 2)));
            }
          }

        }
        else {
        }
      }

      this.needUpdate = false;
    }

    window.requestAnimationFrame(this.update);
  }

  scroll() {
    this.needUpdate = true;
  }

  resize() {
    this.needUpdate = true;
  }

  unmount() {
  }
}

